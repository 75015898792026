<template>
  <b-spinner variant="primary"
             label="Spinner"
             class="page-loading-component"
             v-if="isLoading"></b-spinner>
</template>

<script>
export default {
  name: 'PageLoading',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.page-loading-component {
  height: 3rem;
  width: 3rem;
}
</style>
