<template>
  <b-container class="bonuses-view">
    <PageHeader hideBackButton class="mb-4">
      Бонусы
    </PageHeader>

    <BonusAndGiftSwitcher/>

    <p>
      Профи-клуб развивается вместе с людьми, которые в него входят.
      Сейчас копите бонусы, а когда нас станет немного больше, будем их тратить.
    </p>

    <!-- Лоадер -->
    <PageLoading
        :isLoading="isLoading"
    />
    <!-- /Лоадер -->

    <!-- Ошибка -->
    <b-alert
        show
        v-if="serverErrorMessage"
        variant="danger"
    >
      {{ serverErrorMessage }}
    </b-alert>
    <!-- /Ошибка -->

    <!-- Нет операций -->
    <b-alert
      show
      v-if="!serverErrorMessage && bonusesList.length < 1 && !isLoading"
      variant="info"
    >
      Операции отсутствуют
    </b-alert>
    <!-- /Нет операций -->

    <!-- Карточки с операциями -->
    <div
      v-if="!serverErrorMessage && bonusesList.length > 0"
      class="carts"
    >
      <b-card
        v-for="(bo, index) in bonusesList"
        :key="`bo_${index}`"
        class="carts__item text-left"
      >
        <div
          class="d-flex flex-row flex-wrap justify-content-between align-items-center"
        >
          <!-- Дата и время операции -->
          <small
            class="text-secondary"
          >
            {{ bo.createdAt }}
          </small>

          <!-- Количество -->
          <h5
            class="mb-0"
            :class="{'text-primary': bo.amount >= 0, 'text-danger': bo.amount < 0}"
          >
            <template v-if="bo.amount >= 0">+</template>{{ bo.amount }}
            <b-icon
              icon="lightning-fill"
              aria-hidden="true"
              font-scale="1"
              variant="warning"
            ></b-icon>
          </h5>

          <!-- Описание -->
          <div
            v-if="bo.description"
            class="w-100 mt-2 small"
          >
            {{ bo.description }}
          </div>
        </div>
      </b-card>
    </div>
    <!-- /Карточки с операциями -->

  </b-container>
</template>

<script>
import PageLoading from '../components/PageLoading.vue';
import PageHeader from '../components/PageHeader.vue';
import BonusAndGiftSwitcher from '../components/BonusAndGiftSwitcher.vue';
import { getBonuses } from '../api/profi_club/bonuses';

export default {
  name: 'Bonuses',
  components: {
    PageHeader,
    PageLoading,
    BonusAndGiftSwitcher,
  },
  data() {
    return {
      isLoading: true,
      serverErrorMessage: null,
      bonusesList: [],
    };
  },
  async mounted() {
    this.serverErrorMessage = null;
    this.isLoading = true;
    try {
      const resp = await getBonuses();
      this.bonusesList = resp.operations;
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.serverErrorMessage = e;
    }
  },
};
</script>

<style lang="scss" scoped>
.bonuses-view {
  padding: 25px 10px 50px 10px;
}
</style>
