<template>
  <b-row class="mb-4 w-100">
    <b-col cols="auto">
      <strong v-if="$route.name === 'bonuses'">
        Начисления бонусов
      </strong>
      <b-link v-else :to="{ name: 'bonuses' }">
        Начисления бонусов
      </b-link>
    </b-col>

    <b-col cols="auto">
      <strong v-if="$route.name === 'gifts'">
        Подарки
      </strong>
      <b-link v-else :to="{ name: 'gifts' }">
        Подарки
      </b-link>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'BonusAndGiftSwitcher',
};
</script>
